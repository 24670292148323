<template>
    <v-container style="max-width: 800px">
        <v-row>
            <v-col>
                <v-progress-circular
                    v-if="waiting"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                <v-alert
                    v-else-if="error"
                    type="error"
                >{{ error }}</v-alert>
                <div v-else-if="model">
                    <h1>Превью задания: {{ data.subject }}, {{ data.grade }} класс, {{ data.option }} вариант, {{ data.task }} номер</h1>
                    <task-viewer
                        :model="model"
                        user-role="teacher"
                    />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TaskViewerLib from '@/libraries/task-viewer/index.js'

export default {
    components: { TaskViewer: TaskViewerLib.Viewer },
    name: 'Preview',
    data() {
        return {
            model: null,
            waiting: false,
            error: null
        }
    },
    async created () {
        this.waiting = true;
        const id = _.get(this, '$route.params.id', 0);
        if(id <= 0)
        {
            this.error = 'Неверный запрос.';
        }
        else
        {
            const { success, error, data } = await this.$store.dispatch('task/one', {
                id: id
            });

            if(success) {
                this.data = data;
                this.model = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
            }
            else
                this.error = error;

            this.waiting = false;
        }
    }
}
</script>